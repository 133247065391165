var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-100"},[_c('stats-card',{attrs:{"header-color":"indigo"}},[_c('template',{slot:"header"},[_c('div',{staticClass:"card-icon",on:{"click":function($event){_vm.menuVisible = !_vm.menuVisible}}},[(!_vm.load)?_c('md-icon',[_vm._v("coronavirus")]):_vm._e(),(_vm.load)?_c('i',{staticClass:"md-icon md-icon-font md-theme-default"},[_c('md-progress-spinner',{attrs:{"md-diameter":30,"md-stroke":3,"md-mode":"indeterminate"}})],1):_vm._e()],1)]),_c('template',{slot:"content"},[_c('md-app',[_c('md-app-toolbar',{staticClass:"md-primary",staticStyle:{"padding":"4px"}},[_c('div',{staticClass:"md-toolbar-section-end"},[(!_vm.menuVisible)?_c('md-button',{staticClass:"md-icon-button md-primary",on:{"click":_vm.toggleMenu}},[_c('md-icon',[_vm._v("menu")])],1):_vm._e()],1)]),_c('md-app-drawer',{attrs:{"md-active":_vm.menuVisible,"md-persistent":"full","md-right":"","md-swipeable":""},on:{"update:mdActive":function($event){_vm.menuVisible=$event},"update:md-active":function($event){_vm.menuVisible=$event}}},[_c('md-toolbar',{staticClass:"md-primary",staticStyle:{"padding":"6px"}},[_c('div',{staticClass:"md-toolbar-section-start"},[_c('md-button',{staticClass:"md-icon-button md-dense md-white",on:{"click":_vm.toggleMenu}},[_c('md-icon',[_vm._v("keyboard_arrow_right")])],1)],1),_c('md-button',{staticClass:"md-raised md-white",on:{"click":_vm.changeControl}},[_c('md-icon',[_vm._v("insights")]),_vm._v(" Atualizar ")],1)],1),_c('div',{staticClass:"full-control"},[_c('div',{staticClass:"list"},[_c('md-table',{scopedSlots:_vm._u([{key:"md-table-row",fn:function(ref){
var item = ref.item;
return _c('md-table-row',{attrs:{"md-selectable":"single"}},[_c('md-table-cell',{attrs:{"md-label":"Ano","md-sort-by":"year"}},[_c('md-switch',{attrs:{"value":item.year},on:{"change":_vm.changeControl},model:{value:(_vm.years),callback:function ($$v) {_vm.years=$$v},expression:"years"}})],1),_c('md-table-cell',{attrs:{"md-label":"Opção","md-sort-by":"text"}},[_vm._v(_vm._s(item.year))])],1)}}]),model:{value:(_vm.datasets),callback:function ($$v) {_vm.datasets=$$v},expression:"datasets"}}),_c('md-table',{scopedSlots:_vm._u([{key:"md-table-row",fn:function(ref){
var item = ref.item;
return _c('md-table-row',{attrs:{"md-selectable":"single"}},[_c('md-table-cell',{attrs:{"md-label":"Tipo","md-sort-by":"action"}},[_c('md-switch',{attrs:{"value":item.action},on:{"change":_vm.changeControl},model:{value:(_vm.type_date),callback:function ($$v) {_vm.type_date=$$v},expression:"type_date"}})],1),_c('md-table-cell',{attrs:{"md-label":"Opção","md-sort-by":"text"}},[_vm._v(_vm._s(item.text))])],1)}}]),model:{value:(_vm.type_dates),callback:function ($$v) {_vm.type_dates=$$v},expression:"type_dates"}}),_c('md-table',{scopedSlots:_vm._u([{key:"md-table-row",fn:function(ref){
var item = ref.item;
return _c('md-table-row',{attrs:{"md-selectable":"single"}},[_c('md-table-cell',{attrs:{"md-label":"Filtro","md-sort-by":"action"}},[_c('md-switch',{attrs:{"value":item.action},on:{"change":_vm.changeControl},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('md-table-cell',{attrs:{"md-label":"Opção","md-sort-by":"text"}},[_vm._v(_vm._s(item.text))])],1)}}]),model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})],1)])],1),_c('md-app-content',[_c('div',{attrs:{"id":"chart"}})])],1),_c('md-card',[_c('md-card-content',[_c('md-table',{attrs:{"md-sort":"name","md-sort-order":"asc","md-card":"","md-fixed-header":""},scopedSlots:_vm._u([{key:"md-table-row",fn:function(ref){
var item = ref.item;
return _c('md-table-row',{},[_c('md-table-cell',{attrs:{"md-label":"ID","md-sort-by":"id","md-numeric":""}},[_vm._v(_vm._s(item.id))]),_c('md-table-cell',{attrs:{"md-label":"Estados","md-sort-by":"name"}},[_c('md-checkbox',{attrs:{"value":item.id},on:{"change":function($event){return _vm.getStateCases(item.id)}},model:{value:(_vm.statesSelected),callback:function ($$v) {_vm.statesSelected=$$v},expression:"statesSelected"}}),_vm._v(" "+_vm._s(item.name)+" ")],1),_c('md-table-cell',{attrs:{"md-label":"Capitais","md-sort-by":"capital.name"}},[_c('md-checkbox',{attrs:{"value":item.capital.id},on:{"change":function($event){return _vm.getCapitalCases(item.capital.id)}},model:{value:(_vm.capitalsSelected),callback:function ($$v) {_vm.capitalsSelected=$$v},expression:"capitalsSelected"}}),_vm._v(" "+_vm._s(item.capital.name)+" ")],1),_c('md-table-cell',{attrs:{"md-label":"Regiões","md-sort-by":"region.name"}},[_c('md-checkbox',{attrs:{"value":item.region.id},on:{"change":function($event){return _vm.getRegionCases(item.region.id)}},model:{value:(_vm.regionsSelected),callback:function ($$v) {_vm.regionsSelected=$$v},expression:"regionsSelected"}}),_vm._v(" "+_vm._s(item.region.name)+" ")],1)],1)}}]),model:{value:(_vm.statesSearched),callback:function ($$v) {_vm.statesSearched=$$v},expression:"statesSearched"}},[_c('md-table-toolbar',[_c('div',{staticClass:"md-toolbar-section-start"},[_c('h1',{staticClass:"md-title"},[_vm._v("Localidades")])]),_c('div',{staticClass:"md-toolbar-section-end"},[_c('md-field',{attrs:{"md-clearable":""}},[_c('md-input',{attrs:{"placeholder":"Buscar..."},on:{"click":function($event){$event.stopPropagation();return (function () {
                            return true;
                          }).apply(null, arguments)},"input":function($event){return _vm.searchByName(_vm.states, _vm.termSearch)}},model:{value:(_vm.termSearch),callback:function ($$v) {_vm.termSearch=$$v},expression:"termSearch"}})],1),_c('md-button',{staticClass:"md-raised md-danger",on:{"click":_vm.clearSelect}},[_vm._v("Limpar")])],1)]),_c('md-table-empty-state',{attrs:{"md-label":"Não localizado","md-description":("não foi possivel encontar esta localidade '" + ('text') + "'.")}})],1)],1)],1)],1),_c('template',{slot:"footer"},[_c('div',{staticClass:"stats"})])],2)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }